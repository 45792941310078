import React from "react";
import stepIcon from "assets/stepArrow.svg";
import * as styles from "./Steps.module.scss";

const Steps = ({ step, name }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={step === 1 ? styles.stepActive : styles.step}>
          {name}
        </div>
        <img className={styles.stepIcon} src={stepIcon} alt=">" />
        <div className={step === 2 ? styles.stepActive : styles.step}>
          Shipping
        </div>
        <img className={styles.stepIcon} src={stepIcon} alt=">" />
        <div className={step === 3 ? styles.stepActive : styles.step}>
          Billing
        </div>
      </div>
    </div>
  );
};

export default Steps;
