import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useSiteMetadata } from "hooks/use-site-metadata";

import * as styles from "./PoliciesMenu.module.scss";

const PoliciesMenu = ({ page }) => {
  const { shared } = useSiteMetadata();
  const [selectedItem, setSelectedItem] = useState("membership");

  useEffect(() => {
    page && setSelectedItem(page);
  }, [page]);

  const blogLink = shared.BLOG_LINK || "/underconstruction";
  const newsLink = shared.NEWS_LINK || "/underconstruction";

  return (
    <div className={styles.content}>
      <Link
        to="/membership"
        className={
          selectedItem === "membership"
            ? styles.linkItemSelected
            : styles.linkItem
        }
        onClick={() => setSelectedItem("membership")}
      >
        Vip Club
      </Link>
      <Link
        to="/custom-order"
        className={
          selectedItem === "customorder"
            ? styles.linkItemSelected
            : styles.linkItem
        }
        onClick={() => setSelectedItem("customorder")}
      >
        Custom Order
      </Link>
      <Link
        to={newsLink}
        target="_blank"
        className={
          selectedItem === "underconstruction"
            ? styles.linkItemSelected
            : styles.linkItem
        }
        onClick={() => setSelectedItem("underconstruction")}
      >
        News
      </Link>
      <Link
        to={blogLink}
        target="_blank"
        className={
          selectedItem === { blogLink }
            ? styles.linkItemSelected
            : styles.linkItem
        }
        onClick={() => setSelectedItem(blogLink)}
      >
        Blog
      </Link>
      <Link
        to="/about-us"
        className={
          selectedItem === "about" ? styles.linkItemSelected : styles.linkItem
        }
        onClick={() => setSelectedItem("about")}
      >
        About Us
      </Link>
      <Link
        to="/testimonials"
        className={
          selectedItem === "testimonials"
            ? styles.linkItemSelected
            : styles.linkItem
        }
        onClick={() => setSelectedItem("testimonials")}
      >
        Testimonials
      </Link>
      <Link
        to="/privacy-policy"
        className={
          selectedItem === "privacy" ? styles.linkItemSelected : styles.linkItem
        }
        onClick={() => setSelectedItem("privacy")}
      >
        Privacy Policy
      </Link>
      <Link
        to="/shipping-policy"
        className={
          selectedItem === "shipping"
            ? styles.linkItemSelected
            : styles.linkItem
        }
        onClick={() => setSelectedItem("shipping")}
      >
        Shipping Policy
      </Link>
      <Link
        to="/terms"
        className={
          selectedItem === "terms" ? styles.linkItemSelected : styles.linkItem
        }
        onClick={() => setSelectedItem("terms")}
      >
        Terms And Conditions
      </Link>
      <Link
        to="/refund-policy"
        className={
          selectedItem === "refund" ? styles.linkItemSelected : styles.linkItem
        }
        onClick={() => setSelectedItem("refund")}
      >
        Return & Refund Policy
      </Link>
      <Link
        to="/payment-policy"
        className={
          selectedItem === "payment" ? styles.linkItemSelected : styles.linkItem
        }
        onClick={() => setSelectedItem("payment")}
      >
        Payment Policy
      </Link>
      <Link
        to="/support"
        className={
          selectedItem === "support" ? styles.linkItemSelected : styles.linkItem
        }
        onClick={() => setSelectedItem("support")}
      >
        Contact Us
      </Link>
      <Link
        to="/faq"
        className={
          selectedItem === "faq" ? styles.linkItemSelected : styles.linkItem
        }
        onClick={() => setSelectedItem("faq")}
      >
        FAQs
      </Link>
    </div>
  );
};

export default PoliciesMenu;
