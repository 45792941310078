import React from "react";
import { navigate } from 'utils/navigate'

const PreventDirectLink = ({ shouldRedirect = false }) => {
  if (!shouldRedirect || process.env.NODE_ENV === "development") {
    return <div></div>
  }

  navigate('/')

  return <div></div>
};

export default PreventDirectLink;
