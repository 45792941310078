import React from "react";
import Slider from "react-slick";
import { useSiteMetadata } from "hooks/use-site-metadata";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { navigate } from "utils/navigate";
import Button from "components/Button";
import contactImg from "assets/contact.svg";
import faqImg from "assets/faq.svg";
import refundImg from "assets/refund.svg";
import BarryKImg from "assets/BarryK.jpg";
import CindyLImg from "assets/CindyL.jpg";
import TraceyImg from "assets/tracey.png";
import mediumIcon from "assets/medium.svg";
import facebookIcon from "assets/facebookIconBlack.svg";
import instagramIcon from "assets/instagramIconBlack.svg";
import twitterIcon from "assets/twitterIconBlack.svg";
import * as styles from "./ShopCollection.module.scss";

const testimonials = [
  {
    title: "Tracey W.",
    description:
      "I haven’t taken it off since I got it!!! I have gotten a ton of compliments on it. Love your company!!! Thank you",
    image: {
      publicUrl: TraceyImg,
    },
  },
  {
    title: "Barry K.",
    description:
      "Best decision I’ve made all year. Love the design and it’s comfy.",
    image: {
      publicUrl: BarryKImg,
    },
  },
  {
    title: "Cindy L.",
    description:
      "My new favorite shirt. I get so many compliments when wearing this out. And it really comfortable so thats a plus too.",
    image: {
      publicUrl: CindyLImg,
    },
  },
];

const Support = ({ img, title, children, marginTop, marginBottom }) => (
  <div className={styles.category} style={{ marginTop, marginBottom }}>
    <img src={img} alt="support" className={styles.supportImg} />
    <div className={styles.categoryContainer}>
      <div className={styles.categoryTitle}>{title}</div>
      {children}
    </div>
  </div>
);

const ShopCollection = () => {
  const { shared } = useSiteMetadata();

  const blogLink = shared.BLOG_LINK;
  const fbLink = shared.FACEBOOK_LINK;
  const instaLink = shared.INSTAGRAM_LINK;
  const twitLink = shared.TWITTER_LINK;

  const faqLink = shared.FAQ_LINK;
  const contactUsLink = shared.SUPPORT_LINK;
  const refundPolicyLink = shared.REFUND_POLICY_LINK;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={styles.shop}>
      <div className={styles.section}>
        <div className={styles.title}>Our Happy Winners</div>
        <div className={styles.testimonials}>
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div className={styles.testimonialContainer} key={index}>
                <div className={styles.testimonial}>
                  <img
                    src={testimonial.image.publicUrl}
                    className={styles.testimonialImg}
                    alt="testimonial"
                  />
                  <div className={styles.testimonialDescription}>
                    {testimonial.description}
                  </div>
                  <div className={styles.testimonialTitle}>
                    {testimonial.title}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <a
          className={styles.reviewButton}
          href="/testimonials"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button value="See All Reviews" className="buttonDarkBlue" />
        </a>
      </div>

      <div className={styles.connectSection}>
        <div className={styles.connectTitle}>
          Feel free to connect with us here!
        </div>
        <div className={styles.connectItem}>
          <img src={mediumIcon} alt="Medium" className={styles.icon} />
          <div className={styles.connectTextBox}>
            <div className={styles.connectItemTitle}>Medium blog</div>
            <div className={styles.connectItemText}>
              Read about Fan Made Fits on our Medium Blog!{" "}
              <a
                href={blogLink}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.connectItemTextLink}
              >
                Visit our Medium blog
              </a>
            </div>
          </div>
        </div>

        <div className={styles.connectItem}>
          <img src={facebookIcon} alt="Facebook" className={styles.icon} />
          <div className={styles.connectTextBox}>
            <div className={styles.connectItemTitle}>Facebook Page</div>
            <div className={styles.connectItemText}>
              Connect with fellow fans on Facebook.{" "}
              <a
                href={fbLink}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.connectItemTextLink}
              >
                Visit our Facebook.
              </a>
            </div>
          </div>
        </div>

        <div className={styles.connectItem}>
          <img src={instagramIcon} alt="Instagram" className={styles.icon} />
          <div className={styles.connectTextBox}>
            <div className={styles.connectItemTitle}>Instagram</div>
            <div className={styles.connectItemText}>
              Follow our Instagram for new events and giveaways!{" "}
              <a
                href={instaLink}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.connectItemTextLink}
              >
                Visit our Instagram
              </a>
            </div>
          </div>
        </div>

        <div className={styles.connectItem}>
          <img src={twitterIcon} alt="Twitter" className={styles.icon} />
          <div className={styles.connectTextBox}>
            <div className={styles.connectItemTitle}>Twitter</div>
            <div className={styles.connectItemText}>
              Get daily news and updates on Twitter.{" "}
              <a
                href={twitLink}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.connectItemTextLink}
              >
                Visit our Twitter
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sectionGray}>
        <div className={styles.titleNeedHelp}>Need Help?</div>

        <Support
          img={faqImg}
          title="Have questions about our shop?"
          marginTop={30}
          marginBottom={20}
        >
          <a
            href="/faq"
            target="_blank"
            rel="noopener noreferrer"
            to="/faq"
            className={styles.link}
          >
            Click here to get answers from FAQ’s
          </a>
        </Support>

        <Support
          img={contactImg}
          title="Need to reach out to us?"
          marginBottom={20}
        >
          <a
            href="/support"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.link}
          >
            Click here to contact us
          </a>
        </Support>

        <Support img={refundImg} title="Not completely satisfied?">
          <a
            href="/refund-policy"
            rel="noopener noreferrer"
            target="_blank"
            className={styles.link}
          >
            Click here to see refund policy
          </a>
        </Support>

        <div className={styles.button}>
          <Button
            value="Back to Top"
            customAction={(e) => window.scroll({ top: 0, behavior: "smooth" })}
            className="buttonDarkBlue"
          />
        </div>
      </div>
    </div>
  );
};

export default ShopCollection;
