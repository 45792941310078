import React from "react";
import { navigate } from "utils/navigate";
import * as styles from "./Button.module.scss";

//  classNames:
//    button - standard red button with height = 56px
//    buttonHuge - red button with height = 80px
//    buttonMedium - red button with height = 48px
//    buttonSmall - red button with height = 42px
//    buttonBlue - blue button with height = 48px
//    buttonDarkBlue - dark blue button with height = 80px
//    buttonDarkBlue - dark blue button with height = 48px
//

const Button = ({ value, path, customAction, loading = false, className }) => {
  return (
    <button
      className={className ? styles[className] : styles.button}
      onClick={(e) => {
        if (customAction) {
          customAction(e);
        } else if (path) {
          navigate(path);
        }
      }}
    >
      {loading ? (
        <div className={styles.ldsEllipsisContainer}>
          <div className={styles.ldsEllipsis}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        value
      )}
    </button>
  );
};

export default Button;
