// extracted by mini-css-extract-plugin
export var quotesContainer = "QuotesTable-module--quotesContainer--3e7fn";
export var quotesTitle = "QuotesTable-module--quotesTitle--3gqUJ";
export var tableRowTitle = "QuotesTable-module--tableRowTitle--3_6gI";
export var tableRow = "QuotesTable-module--tableRow--22JsB";
export var tableTitle = "QuotesTable-module--tableTitle--3Vt3q";
export var tableSubtitleContainer = "QuotesTable-module--tableSubtitleContainer--1cXut";
export var subtitleBig = "QuotesTable-module--subtitleBig--2PzNk";
export var subtitleSmall = "QuotesTable-module--subtitleSmall--l3JCw";
export var rowItem1 = "QuotesTable-module--rowItem1--RyKsk";
export var rowText1 = "QuotesTable-module--rowText1--dY7PK";
export var rowText2 = "QuotesTable-module--rowText2--1wGQ4";
export var rowTextRed = "QuotesTable-module--rowTextRed--2tDlZ";
export var rowTextBlack = "QuotesTable-module--rowTextBlack--jQwG2";
export var rowItem2 = "QuotesTable-module--rowItem2--wqd_J";
export var rowItem3 = "QuotesTable-module--rowItem3--1pRBb";
export var contactButton = "QuotesTable-module--contactButton--3KWb2";
export var link = "QuotesTable-module--link--2H5Yj";