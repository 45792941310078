import React, { useState, useEffect } from "react";
import * as styles from "./InputCvv.module.scss";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

const InputCvv = ({
  placeholder,
  label,
  onChange,
  isValid,
  isShowErrors = false,
  errorMessage,
  defaultValue,
  pattern = null,
}) => {
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (value !== defaultValue) {
      onChange && onChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, onChange]);

  const onChangeHandler = (event) => {
    setValue(pattern ? pattern(event) : event.target.value);
  };

  return (
    <div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.inputContainer
            : styles.inputContainerError
        }
      >
        <div className={styles.label}>{label}</div>
        <input
          type="password"
          className={styles.input}
          name="input"
          value={value}
          placeholder={placeholder}
          onChange={onChangeHandler}
        />
      </div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.errorMessageHidden
            : styles.errorMessage
        }
      >
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

export default InputCvv;
