import React, { useState, useEffect } from "react";
import styled from "styled-components";
import infoIcon from "assets/infoIcon.svg";
import closeIcon from "assets/close-menu-icon-black.svg";
import * as styles from "./Notification.module.scss";

const Container = styled.div`
  background-color: #fafafa;
  border-radius: 10px;
  color: white;
  padding: 30px 30px 38px;
  position: fixed;
  top: ${(props) => props.top};
  left: 50%;
  z-index: 999;
  transition: top 0.5s ease;
  transform: translate(-50%, -50%);
  width: ${(typeof window !== 'undefined' && window.innerWidth || 0) < 720 ? "310px" : "340px"};
  ${(props) =>
    props.top === "50%" && "box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.7);"}
`;

const Notification = ({
  message,
  title = "Error",
  onNotificationClosed = null,
}) => {
  const [top, setTop] = useState("-200px");

  useEffect(() => {
    let removeInterval;

    if (message) {
      setTop("50%");
    }
    return () => {
      setTop("-200px");
      clearInterval(removeInterval);
    };
  }, [message]);

  useEffect(() => {
    if (top === "-200px") {
      onNotificationClosed && onNotificationClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top]);

  return (
    <div>
      <Container top={top}>
        <div className={styles.headerContainer}>
          <img className={styles.infoIcon} src={infoIcon} alt="" />
          <div className={styles.errorTitle}>{title}</div>
        </div>
        <div
          className={title === "Error" ? styles.errorMessage : styles.message}
        >
          {message}
        </div>
        <img
          className={styles.closeIcon}
          src={closeIcon}
          alt=""
          onClick={() => setTop("-200px")}
        />
      </Container>
    </div>
  );
};

export default Notification;
