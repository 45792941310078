import React from "react";
import { Button } from "components";
import checkRedIcon from "assets/checkRed.svg";
import closeGrayIcon from "assets/closeGray.svg";
import * as styles from "./EvenMoreTable.module.scss";

const EvenMoreTable = () => {
  return (
    <div className={styles.tableContainer}>
      <p className={styles.quotesTitle}>VIPs get even more</p>
      <div className={styles.table}>
        <div className={styles.tableRowTitle}>
          <div className={styles.tableTitle}>Membership Benefits</div>
          <div className={styles.tableSubtitleContainer1}>
            <p className={styles.subtitleBig}>Non-VIP</p>
            <p className={styles.subtitleSmall}>Members</p>
          </div>
          <div className={styles.tableSubtitleContainer2}>
            <p className={styles.subtitleBig}>VIP</p>
            <p className={styles.subtitleSmall}>Members</p>
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>Best shopping experience</p>
            <p className={styles.rowText2}>
              •  Find anything you need at fan made fits
            </p>
          </div>
          <div className={styles.rowItem2}>
            <img className={styles.icon} src={checkRedIcon} alt="YES" />
          </div>
          <div className={styles.rowItem3}>
            <img className={styles.icon} src={checkRedIcon} alt="YES" />
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>Talk to personal concierge</p>
            <p className={styles.rowText2}>
              •  24/7 personal concierge service
            </p>
            <p className={styles.rowText2}>•  cancellations and questions</p>
            <p className={styles.rowText2}>
              •  request any design or product to concierge
            </p>
            <p className={styles.rowText2}>•  ask or talk about anything</p>
          </div>
          <div className={styles.rowItem2}>
            <img className={styles.icon} src={checkRedIcon} alt="YES" />
          </div>
          <div className={styles.rowItem3}>
            <img className={styles.icon} src={checkRedIcon} alt="YES" />
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>Shop for FREE</p>
            <p className={styles.rowText2}>
              •  Shop any product on the site for free (Just Pay Shipping)
            </p>
          </div>
          <div className={styles.rowItem2}>
            <img className={styles.icon} src={closeGrayIcon} alt="NO" />
          </div>
          <div className={styles.rowItem3}>
            <img className={styles.icon} src={checkRedIcon} alt="YES" />
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>Premium Shipping</p>
            <p className={styles.rowText2}>
              •  Premium shipping is included with every order
            </p>
          </div>
          <div className={styles.rowItem2}>
            <img className={styles.icon} src={closeGrayIcon} alt="NO" />
          </div>
          <div className={styles.rowItem3}>
            <img className={styles.icon} src={checkRedIcon} alt="YES" />
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>Custom Order</p>
            <p className={styles.rowText2}>
              •  Premium shipping is included with every order
            </p>
          </div>
          <div className={styles.rowItem2}>
            <img className={styles.icon} src={closeGrayIcon} alt="NO" />
          </div>
          <div className={styles.rowItem3}>
            <img className={styles.iconClose} src={checkRedIcon} alt="YES" />
          </div>
        </div>

        <div className={styles.contactButton}>
          <Button value="Sign Up Today" className="button69" path="/register" />
        </div>
      </div>
    </div>
  );
};

export default EvenMoreTable;
