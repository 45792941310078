import React from 'react'
import * as styles from './ProfileOrderItem.module.scss'

const ProfileOrderItem = ({ order }) => {
  const myDate = new Date(order.createdAt)
  const month = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sep.', 'Oct.', 'Nov', 'Dec.'][myDate.getMonth()]
  const year = myDate.getFullYear()
  const day = myDate.getDate()
  const date = `${month} ${day}, ${year}`

  const sizeAttribute = order.items[0]?.attributes.find(i => i.attribute === 'size') || ''

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <p className={styles.title}>Order Number {order.orderId}</p>
        <p className={styles.text}>Ordered On {date}</p>
        <br />
        <p className={styles.text}>Tracking #{order.trackingNumber}</p>
        <br />
        <p className={styles.text}>
          {order.customerFirstName} {order.customerLastName}
        </p>
        <p className={styles.text}>
          {order.shippingAddress} {order.shippingAddress2}
        </p>
        <p className={styles.text}>
          {order.shippingCity}, {order.shippingState}
        </p>
        <p className={styles.text}>{order.shippingZipCode}</p>
      </div>
      <div className={styles.box}>
        <div className={styles.column}>
          <p className={styles.text}>{order.items[0] && order.items[0].displayName}</p>
          <br />
          {sizeAttribute && <p className={styles.text}>Size {sizeAttribute?.value}</p>}
          <p className={styles.text}>${order.items[0] && order.items[0].finalPrice}</p>
          <p className={styles.text}>Order Total ${order && order.total}</p>
          <br />
          <p className={styles.textLight}>(Please check email for receipt)</p>
        </div>
        {order.items[0] && order.items[0].imagePublicUrl && <img className={styles.image} src={order.items[0].imagePublicUrl} alt='' />}
      </div>
    </div>
  )
}

export default ProfileOrderItem
