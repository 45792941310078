import { useEffect } from 'react';

const FacebookPixels = ({ id = '', event = '' }) => {
  useEffect(() => {
    import('react-facebook-pixel')
      .then(module => module.default)
      .then(ReactPixel => {
        ReactPixel.init(id);
        ReactPixel.pageView();
        if (event) {
          ReactPixel.track(event);
        }
      })
  }, [])

  return null;
};

export default FacebookPixels;
