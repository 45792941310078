import React, { useState, useEffect } from "react";
import { Rifm } from "rifm";
import visaImage from "assets/visa.png";
import mastercardImage from "assets/mastercard.png";
import discoverImage from "assets/discover.png";
import * as styles from "./CreditCardInput.module.scss";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

const parseDigits = (string) => (string.match(/\d+/g) || []).join("");

const formatCreditCard = (string) => {
  const digits = parseDigits(string);
  const chars = digits.split("");
  return chars
    .reduce(
      (r, v, index) =>
        index === 4 || index === 8 || index === 12 || index === 16
          ? `${r} ${v}`
          : `${r}${v}`,
      ""
    )
    .substr(0, 22);
};

const CreditCardInput = ({
  placeholder,
  label,
  onChange,
  isValid,
  isShowErrors = false,
  errorMessage,
  defaultValue,
}) => {
  const [masked, setMasked] = React.useState("");
  const debouncedValue = useDebounce(masked, 300);

  useEffect(() => {
    if (masked !== defaultValue) {
      onChange && onChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, onChange]);

  return (
    <div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.inputContainer
            : styles.inputContainerError
        }
      >
        <div className={styles.textBox}>
          <div className={styles.inputLabel}>{label}</div>
          <div>
            <Rifm
              accept={/[\d]/g}
              mask={true}
              format={formatCreditCard}
              value={masked}
              onChange={setMasked}
            >
              {({ value, onChange }) => (
                <input
                  type="tel"
                  className={styles.input}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                />
              )}
            </Rifm>
          </div>
        </div>

        <div className={styles.inputImagesContainer}>
          <img className={styles.image} src={visaImage} alt="" />
          <img className={styles.image} src={mastercardImage} alt="" />
          <img className={styles.image} src={discoverImage} alt="" />
        </div>
      </div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.errorMessageHidden
            : styles.errorMessage
        }
      >
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

export default CreditCardInput;
