// extracted by mini-css-extract-plugin
export var shop = "ShopCollection-module--shop--3D9S_";
export var section = "ShopCollection-module--section--2ahkg";
export var sectionGray = "ShopCollection-module--sectionGray--TMk5e";
export var sectionPromo = "ShopCollection-module--sectionPromo--3dn2Y";
export var title = "ShopCollection-module--title--1nBm_";
export var testimonials = "ShopCollection-module--testimonials--1KfZn";
export var testimonialContainer = "ShopCollection-module--testimonialContainer--3bW0y";
export var testimonial = "ShopCollection-module--testimonial--3Jv9X";
export var testimonialImg = "ShopCollection-module--testimonialImg--34JiM";
export var testimonialTitle = "ShopCollection-module--testimonialTitle--2iNWD";
export var testimonialDescription = "ShopCollection-module--testimonialDescription--3Tv-z";
export var reviewButton = "ShopCollection-module--reviewButton--2G2pT";
export var connectSection = "ShopCollection-module--connectSection--25F7C";
export var connectTitle = "ShopCollection-module--connectTitle--Ty_Fc";
export var connectItem = "ShopCollection-module--connectItem--3UNhQ";
export var icon = "ShopCollection-module--icon--VZHB9";
export var connectTextBox = "ShopCollection-module--connectTextBox--K1IOS";
export var connectItemTitle = "ShopCollection-module--connectItemTitle--35YhM";
export var connectItemText = "ShopCollection-module--connectItemText--3d_kO";
export var connectItemTextLink = "ShopCollection-module--connectItemTextLink--1qamZ";
export var titleNeedHelp = "ShopCollection-module--titleNeedHelp--3c5Kk";
export var category = "ShopCollection-module--category--3kuVl";
export var categoryContainer = "ShopCollection-module--categoryContainer--SH8ac";
export var categoryTitle = "ShopCollection-module--categoryTitle--keYTN";
export var link = "ShopCollection-module--link--w_0fN";
export var supportImg = "ShopCollection-module--supportImg--1XDp1";
export var button = "ShopCollection-module--button--2sStz";