// extracted by mini-css-extract-plugin
export var tableContainer = "EvenMoreTable-module--tableContainer--3Y9YI";
export var quotesTitle = "EvenMoreTable-module--quotesTitle--1qgT8";
export var tableRowTitle = "EvenMoreTable-module--tableRowTitle--1rDXD";
export var tableRow = "EvenMoreTable-module--tableRow--fjnLp";
export var tableTitle = "EvenMoreTable-module--tableTitle--EKqKM";
export var tableSubtitleContainer1 = "EvenMoreTable-module--tableSubtitleContainer1--3gwjE";
export var tableSubtitleContainer2 = "EvenMoreTable-module--tableSubtitleContainer2--L96I-";
export var subtitleBig = "EvenMoreTable-module--subtitleBig--tWdjg";
export var subtitleSmall = "EvenMoreTable-module--subtitleSmall--oX6-1";
export var rowItem1 = "EvenMoreTable-module--rowItem1--1ZSOI";
export var rowText1 = "EvenMoreTable-module--rowText1--31dai";
export var rowText2 = "EvenMoreTable-module--rowText2--1EjMg";
export var rowTextRed = "EvenMoreTable-module--rowTextRed--3b7Dd";
export var rowTextBlack = "EvenMoreTable-module--rowTextBlack--1fk_m";
export var rowItem2 = "EvenMoreTable-module--rowItem2--1sQZA";
export var rowItem3 = "EvenMoreTable-module--rowItem3--33fjI";
export var icon = "EvenMoreTable-module--icon--2cryF";
export var iconClose = "EvenMoreTable-module--iconClose--3tS18";
export var contactButton = "EvenMoreTable-module--contactButton--1DPyx";