import React, { useState, useEffect } from 'react';
import * as JsSearch from "js-search";
import { stemmer } from "porter-stemmer";

const Searchable = ({ searchText, searchSource, keys, children: Component }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const search = new JsSearch.Search("id");
    search.tokenizer = new JsSearch.StemmingTokenizer(
      stemmer,
      new JsSearch.SimpleTokenizer()
    );

    for (const key of keys) {
      search.addIndex(key);
    }

    search.addDocuments(searchSource);

    setData(search.search(searchText));  
  }, [searchText, searchSource, keys])

  return (
    <Component data={data} />
  );
};

export default Searchable;
