// extracted by mini-css-extract-plugin
export var vip = "VipClub-module--vip--3XnmG";
export var breadcrumbs = "VipClub-module--breadcrumbs--3t_fV";
export var title = "VipClub-module--title--xLjwl";
export var subtitle = "VipClub-module--subtitle--2-qUm";
export var gallery = "VipClub-module--gallery--T4g6s";
export var galleryRow = "VipClub-module--galleryRow--31CEa";
export var galleryItem = "VipClub-module--galleryItem--2zR78";
export var galleryTitle = "VipClub-module--galleryTitle--3VcqH";
export var galleryImg = "VipClub-module--galleryImg--E9GOx";
export var galleryPrice = "VipClub-module--galleryPrice--3Vd70";
export var galleryShipping = "VipClub-module--galleryShipping--3DRVo";
export var galleryComing = "VipClub-module--galleryComing--3FMng";
export var disclaimer = "VipClub-module--disclaimer--2GOao";
export var disclaimerBold = "VipClub-module--disclaimerBold--1b9wK";
export var button = "VipClub-module--button--25hpA";
export var customOrderTitle = "VipClub-module--customOrderTitle--WJNMC";
export var customOrderSubtitle = "VipClub-module--customOrderSubtitle--38NJ9";
export var card = "VipClub-module--card--2pmv9";
export var cardImg = "VipClub-module--cardImg--rRJym";
export var cardTitle = "VipClub-module--cardTitle--zdp1c";
export var cardText = "VipClub-module--cardText--2QmxT";
export var cardDesktop = "VipClub-module--cardDesktop--3oyW2";
export var textBox = "VipClub-module--textBox--2-cpQ";
export var summury = "VipClub-module--summury--148ek";
export var howItWorks = "VipClub-module--howItWorks--12Eed";
export var howItWorksTitle = "VipClub-module--howItWorksTitle--1B88f";
export var howItWorksItem = "VipClub-module--howItWorksItem--1nVxw";
export var number = "VipClub-module--number--VVb-H";
export var cardColumn = "VipClub-module--cardColumn--2FI-t";
export var cardColumnTitle = "VipClub-module--cardColumnTitle--23UeJ";
export var cardColumnText = "VipClub-module--cardColumnText--38ssk";
export var lovePrizes = "VipClub-module--lovePrizes--3gvVN";
export var lovePrizesTitle = "VipClub-module--lovePrizesTitle--1KMse";
export var lovePrizesText = "VipClub-module--lovePrizesText--NCALq";
export var lovePrizesText2 = "VipClub-module--lovePrizesText2--17wrF";
export var follow = "VipClub-module--follow--2SIGE";
export var social = "VipClub-module--social--TOWYd";
export var icon = "VipClub-module--icon--KDF3A";
export var socialLink = "VipClub-module--socialLink--Srpkr";
export var infoBlock = "VipClub-module--infoBlock--2RZR4";
export var infoBlockTitle = "VipClub-module--infoBlockTitle--2sk27";
export var infoBlockText = "VipClub-module--infoBlockText--2qX6q";
export var infoBlockText2 = "VipClub-module--infoBlockText2--2XnK6";
export var infoBlockButton = "VipClub-module--infoBlockButton--3sAEX";
export var linkButton = "VipClub-module--linkButton--2W0Op";
export var linkText = "VipClub-module--linkText--1L9a7";