import React from "react";
import { useSiteMetadata } from 'hooks/use-site-metadata'
import facebookIcon from "assets/facebookIconBlack.svg";
import instagramIcon from "assets/instagramIconBlack.svg";
import twitterIcon from "assets/twitterIconBlack.svg";
import * as styles from "./FollowUs.module.scss";

const FollowUs = () => {
  const { shared } = useSiteMetadata()

  const facebookLink = shared.FACEBOOK_LINK;
  const instagramLink = shared.INSTAGRAM_LINK;
  const twitterLink = shared.TWITTER_LINK;

  return (
    <div className={styles.content}>
      <div className={styles.title}>Follow Us</div>

      <div className={styles.socialContainer}>
        <a href={facebookLink} target="blank">
          <img className={styles.socialIcon} src={facebookIcon} alt=""></img>
        </a>
        <a href={instagramLink} target="blank">
          <img className={styles.socialIcon} src={instagramIcon} alt=""></img>
        </a>
        <a href={twitterLink} target="blank">
          <img className={styles.socialIcon} src={twitterIcon} alt=""></img>
        </a>
      </div>
    </div>
  );
};

export default FollowUs;
