// extracted by mini-css-extract-plugin
export var container = "InputSearch-module--container--3zVKY";
export var containerOpened = "InputSearch-module--containerOpened--2z9tS";
export var searchIcon = "InputSearch-module--searchIcon--39JHG";
export var arrowIcon = "InputSearch-module--arrowIcon--1yB2Y";
export var input = "InputSearch-module--input--25WA9";
export var errorMessage = "InputSearch-module--errorMessage--3MeAE";
export var errorMessageHidden = "InputSearch-module--errorMessageHidden--JZqUD";
export var suggestionsWrapper = "InputSearch-module--suggestionsWrapper--3trPt";
export var suggestions = "InputSearch-module--suggestions--3X332";
export var suggestion = "InputSearch-module--suggestion--rO31U";
export var suggestionTitle = "InputSearch-module--suggestionTitle--24aqi";