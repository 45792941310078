// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3mBiF";
export var footerLogo = "Footer-module--footerLogo--3FJKh";
export var infoContainer = "Footer-module--infoContainer--2blJX";
export var infoTitle = "Footer-module--infoTitle--1nH25";
export var info = "Footer-module--info--2wr65";
export var container = "Footer-module--container--vsDRR";
export var campaignContainer = "Footer-module--campaignContainer--IGdO4";
export var followText = "Footer-module--followText--3HZ8C";
export var logo = "Footer-module--logo--3Y3gK";
export var socialContainer = "Footer-module--socialContainer--DhdRu";
export var socialItem = "Footer-module--socialItem--10Pgp";
export var socialIcon = "Footer-module--socialIcon--32EdI";
export var campaignSupport = "Footer-module--campaignSupport--qDC6a";
export var containerRight = "Footer-module--containerRight--1d5jx";
export var supportContainer = "Footer-module--supportContainer--16EoM";
export var columnTitle = "Footer-module--columnTitle--1Og53";
export var linkItem = "Footer-module--linkItem--e5kgr";
export var copyrightContainer = "Footer-module--copyrightContainer--3Ldp7";
export var copyrightImageContainer = "Footer-module--copyrightImageContainer--1nPLV";
export var copyrightImage = "Footer-module--copyrightImage--35f6u";