import React from "react";

const SampleArrow = (props) => {
  const { onClick, internalClassName, img } = props;
  return (
    <img className={internalClassName} src={img} alt="" onClick={onClick} />
  );
};

export default SampleArrow;
