import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'utils/navigate'
import { connect } from 'react-redux'
import { selectMenu } from 'state/HomePage/reducer'
import { customerAccountRefreshToken } from 'state/LoginPage/actions'
import { selectLoginState } from 'state/LoginPage/reducer'
import Menu from 'components/Menu'
import * as styles from './BurgerMenu.module.scss'

const BurgerMenu = ({ menu, handleCloseMenu, gap }) => {
  const [isfFullMenu, setIsfFullMenu] = useState(true)

  const menuBlockRef = useRef()
  const overlayRef = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const handleClickOutside = e => {
    if (!menuBlockRef.current.contains(e.target) && overlayRef.current.contains(e.target)) {
      handleCloseMenu()
    }
  }

  const handleCollectionClicked = category => {
    if (!category) {
      setIsfFullMenu(true)
    } else {
      navigate(`/category/${category}`)
      handleCloseMenu()
    }
  }

  return (
    <div className={gap === true ? styles.bodyOverlayWithGap : styles.bodyOverlay} ref={overlayRef}>
      <div className={gap === true ? styles.menuWithGap : styles.menu} ref={menuBlockRef} onClick={handleClickOutside}>
        <Menu menu={menu} onCollectionCliked={handleCollectionClicked} onShopClick={() => setIsfFullMenu(false)} isfFullMenu={isfFullMenu} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  loginState: selectLoginState(state)
})

const mapDispatchToProps = {
  customerAccountRefreshToken
}

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu)
