// extracted by mini-css-extract-plugin
export var header = "Header-module--header--1Qt4T";
export var nav = "Header-module--nav--y_ARd";
export var logo = "Header-module--logo--RPmCm";
export var burgerIcon = "Header-module--burgerIcon--2seRA";
export var iconsContainer = "Header-module--iconsContainer--2oq8K";
export var headerSearch = "Header-module--headerSearch--2th-I";
export var headerSearch_active = "Header-module--headerSearch_active--2wBVX";
export var inputSearch = "Header-module--inputSearch--1z5nj";
export var searchIcon = "Header-module--searchIcon--3xUug";
export var userIcon = "Header-module--userIcon--3RU_D";
export var userName = "Header-module--userName--2Vj-s";
export var trendings = "Header-module--trendings--2efb7";
export var trendingWithGap = "Header-module--trendingWithGap--1KjYz";
export var trendingsContent = "Header-module--trendingsContent--1z5TY";
export var trendingsBox = "Header-module--trendingsBox--3ibx5";
export var trendingsTitle = "Header-module--trendingsTitle--ojh0i";
export var trendingsLink = "Header-module--trendingsLink--15mjD";
export var trendingsNavigation = "Header-module--trendingsNavigation--2ZTkH";
export var trendingsForm = "Header-module--trendingsForm--3OjCs";
export var trendingSearch = "Header-module--trendingSearch--cJ4lo";
export var trendingsCancel = "Header-module--trendingsCancel--1rYzC";
export var box = "Header-module--box--3yPPi";
export var description = "Header-module--description--1i4lX";
export var searchContainer = "Header-module--searchContainer--3wdfn";