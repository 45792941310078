import React from 'react'
import Helmet from 'react-helmet'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import logoImage from 'assets/fmfLogoBlack.png'

const HotjarScript = ({ hotjarSiteId }) => {
  const hotjarSite = hotjarSiteId.replace(/[^\d]+/gm, '')

  const script = `
    <script>
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hotjarSite},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    </script>
  `
  if (hotjarSiteId) {
    return script
  } else {
    return null
  }
}

const decryptScriptTags = (source) => source
  .replace(/\[\%\{script\}\%\]/g, "<script>")
  .replace(/\[\%\{\/script\}\%\]/g, "</script>")
  .replace(/\[\%\{script /g, "<script ")
  .replace(/`/g, '"')

const SEO = ({ description, image, meta = [], keywords, title, pathname }) => {
  const siteMetadata = useSiteMetadata()

  const metaTitle = title || siteMetadata.title
  const metaDescription = description || siteMetadata.description
  const metaKeywords = keywords || siteMetadata.keywords
  const metaImage = image ? image : logoImage
  const metaUrl = `${siteMetadata.siteUrl}${pathname}`

  let AntiFlickerSnippet
  if (siteMetadata.shared.ANTI_FLICKER_SNIPPET) {
    AntiFlickerSnippet = decruptScriptTags(siteMetadata.shared.ANTI_FLICKER_SNIPPET)
  }

  let GoogleOptimizeShippet
  if (siteMetadata.shared.GOOGLE_OPTIMIZE_SNIPPET) {
    GoogleOptimizeShippet = decryptScriptTags(siteMetadata.shared.GOOGLE_OPTIMIZE_SNIPPET)
  }


  let GoogleShoppingSnippet
  if (siteMetadata.shared.GOOGLE_SHOPPING_SNIPPET) {
    GoogleShoppingSnippet = decryptScriptTags(siteMetadata.shared.GOOGLE_SHOPPING_SNIPPET)
  }


  let GoogleGlobalTagNoScript
  if (siteMetadata.shared.GOOGLE_GLOBAL_TAG_NOSCRIPT) {
    GoogleGlobalTagNoScript = decryptScriptTags(siteMetadata.shared.GOOGLE_GLOBAL_TAG_NOSCRIPT)
  }


  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1`
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // {
        //   name: 'google-site-verification',
        //   content: 'QlRmuLQWttdkbKlZ0ZwIBX3xv0M8ouqTW3wE2Eg_jKI'
        // }
      ]
        .concat(siteMetadata.landing.TWITTER_LINK
          ? [{
            name: 'twitter:creater',
            content: `@${siteMetadata.landing.TWITTER_LINK.replace(/(.+)\//, '')}`
          }] : []
        )
        .concat(metaImage ? [
          {
            property: `og:image`,
            content: metaImage
          },
          {
            property: `og:image:alt`,
            content: metaTitle,
          },
          {
            property: 'og:image:width',
            content: 200
          },
          {
            property: 'og:image:height',
            content: 200
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          }
        ] : [
          {
            name: `twitter:card`,
            content: `summary`,
          },
        ])
        .concat(metaKeywords
          ? [{
              name: `keywords`,
              content: metaKeywords,
          }] : []
        )
        .concat(meta)}
    >
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${siteMetadata.shared.GOOGLE_GLOBAL_TAG}`} />
      <script type="text/javascript">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());`}
      </script>
     </Helmet>
  )
}

export default SEO