import React from "react";
import { Link } from "gatsby";
import { useSiteMetadata } from "hooks/use-site-metadata";
import { Button } from "components";
import tshirtImg from "assets/tshirtButterfly.png";
import hoodieImg from "assets/hoodieKorgy.png";
import sweatshirtImg from "assets/sweatshirt.png";
import totebagImg from "assets/totebag.png";
import capImg from "assets/cap.png";
import sweatpantImg from "assets/sweatpant.png";
import hobbieImg from "assets/hobbie.png";
import charityImg from "assets/charity.png";
import eventImg from "assets/event.png";
import hobbieBigImg from "assets/hobbieBig.png";
import charityBigImg from "assets/charityBig.png";
import eventBigImg from "assets/eventBig.png";
import fbIcon from "assets/facebookIconBlack.svg";
import instIcon from "assets/instagramIconBlack.svg";
import twitIcon from "assets/twitterIconBlack.svg";
import * as styles from "./VipClub.module.scss";

const VipClub = () => {
  const { shared, landing } = useSiteMetadata();

  const facebookLink = shared.FACEBOOK_LINK;
  const instagramLink = shared.INSTAGRAM_LINK;
  const twitterLink = shared.TWITTER_LINK;
  const supportEmail = shared.SUPPORT_EMAIL;

  return (
    <div className={styles.vip}>
      <div className={styles.breadcrumbs}>VIP Club</div>
      <div className={styles.title}>What can VIPs Expect?</div>
      <div className={styles.subtitle}>
        VIP-only Pricing - “0 Profit Pricing”
      </div>

      <section className={styles.gallery}>
        <div className={styles.galleryRow}>
          <div className={styles.galleryItem}>
            <p className={styles.galleryTitle}>T-Shirts</p>
            <img className={styles.galleryImg} src={tshirtImg} alt="Tshirts" />
            <p className={styles.galleryPrice}>$0.00</p>
            <p className={styles.galleryShipping}>(+ shipping)</p>
          </div>
          <div className={styles.galleryItem}>
            <p className={styles.galleryTitle}>Hoodies</p>
            <img className={styles.galleryImg} src={hoodieImg} alt="Hoodies" />
            <p className={styles.galleryPrice}>$9.99</p>
            <p className={styles.galleryShipping}>(+ shipping)</p>
          </div>
          <div className={styles.galleryItem}>
            <p className={styles.galleryTitle}>Sweatshirt</p>
            <img
              className={styles.galleryImg}
              src={sweatshirtImg}
              alt="Sweatshirts"
            />
            <p className={styles.galleryPrice}>$9.99</p>
            <p className={styles.galleryShipping}>(+ shipping)</p>
          </div>
        </div>
        <div className={styles.galleryRow}>
          <div className={styles.galleryItem}>
            <p className={styles.galleryTitle}>Tote bag</p>
            <img
              className={styles.galleryImg}
              src={totebagImg}
              alt="Tote bags"
            />
            <p className={styles.galleryComing}>Coming</p>
            <p className={styles.galleryComing}>Soon</p>
          </div>
          <div className={styles.galleryItem}>
            <p className={styles.galleryTitle}>Cap</p>
            <img className={styles.galleryImg} src={capImg} alt="Caps" />
            <p className={styles.galleryComing}>Coming</p>
            <p className={styles.galleryComing}>Soon</p>
          </div>
          <div className={styles.galleryItem}>
            <p className={styles.galleryTitle}>Sweatpant</p>
            <img
              className={styles.galleryImg}
              src={sweatpantImg}
              alt="Sweatpants"
            />
            <p className={styles.galleryComing}>Coming</p>
            <p className={styles.galleryComing}>Soon</p>
          </div>
        </div>
      </section>
      <div className={styles.disclaimer}>
        All products are{" "}
        <span className={styles.disclaimerBold}>“zero profit”</span> for us,
        meaning you get all these products{" "}
        <span className={styles.disclaimerBold}>“at cost”</span> , for being a
        VIP.
      </div>
      <div className={styles.button}>
        <Button value="Start Custom Order" path="/custom-order" />
      </div>
      <div className={styles.customOrderTitle}>
        Custom Orders - Request Designs - Your Ideas Come to Life
      </div>
      <div className={styles.customOrderSubtitle}>
        Create your own design and build your own merch brand!
      </div>
      <div className={styles.card}>
        <img className={styles.cardImg} src={hobbieBigImg} alt="Hobbie" />
        <p className={styles.cardTitle}>Are you involved in a hobby?</p>
        <p className={styles.cardText}>
          Most of us are (weekly crochet group, pickup basketball team, music
          band)
        </p>
      </div>
      <div className={styles.card}>
        <img className={styles.cardImg} src={charityBigImg} alt="Charity" />
        <p className={styles.cardTitle}>
          Are you involved in a charity or movement?
        </p>
        <p className={styles.cardText}>
          (supporting disabled vets, church bible study, autism)
        </p>
      </div>
      <div className={styles.card}>
        <img className={styles.cardImg} src={eventBigImg} alt="Events" />
        <p className={styles.cardTitle}>Are you hosting an event?</p>
        <p className={styles.cardText}>(birthday party, anniversary, outing)</p>
      </div>

      <div className={styles.cardDesktop}>
        <img className={styles.cardImg} src={hobbieImg} alt="Hobbie" />
        <div className={styles.textBox}>
          <p className={styles.cardTitle}>Are you involved in a hobby?</p>
          <p className={styles.cardText}>
            Most of us are (weekly crochet group, pickup basketball team, music
            band)
          </p>
        </div>
      </div>
      <div className={styles.cardDesktop}>
        <img className={styles.cardImg} src={charityImg} alt="Charity" />
        <div className={styles.textBox}>
          <p className={styles.cardTitle}>
            Are you involved in a charity or movement?
          </p>
          <p className={styles.cardText}>
            (supporting disabled vets, church bible study, autism)
          </p>
        </div>
      </div>
      <div className={styles.cardDesktop}>
        <img className={styles.cardImg} src={eventImg} alt="Events" />
        <div className={styles.textBox}>
          <p className={styles.cardTitle}>Are you hosting an event?</p>
          <p className={styles.cardText}>
            (birthday party, anniversary, outing)
          </p>
        </div>
      </div>
      <div className={styles.summury}>
        We have a team of designers ready to create your idea from scratch.
      </div>

      <div className={styles.howItWorks}>
        <p className={styles.howItWorksTitle}>How It Works:</p>
        <div className={styles.howItWorksItem}>
          <div className={styles.number}>1</div>
          <div className={styles.cardColumn}>
            <p className={styles.cardColumnTitle}>
              Reach out to our custom design team
            </p>
            <p className={styles.cardColumnText}>Fan Made Fits Concierge</p>
          </div>
        </div>

        <div className={styles.howItWorksItem}>
          <div className={styles.number}>2</div>
          <div className={styles.cardColumn}>
            <p className={styles.cardColumnTitle}>Tell us your idea</p>
            <p className={styles.cardColumnText}>
              Examples, or even just tell us what you’re thinking via phone or
              message
            </p>
          </div>
        </div>

        <div className={styles.howItWorksItem}>
          <div className={styles.number}>3</div>
          <div className={styles.cardColumn}>
            <p className={styles.cardColumnTitle}>
              Let our designers give you a couple options
            </p>
            <p className={styles.cardColumnText}>Edit as needed!</p>
          </div>
        </div>

        <div className={styles.howItWorksItem}>
          <div className={styles.number}>4</div>
          <div className={styles.cardColumn}>
            <p className={styles.cardColumnTitle}>Get VIP pricing</p>
            <p className={styles.cardColumnText}>See our VIP pricing</p>
          </div>
        </div>

        <div className={styles.howItWorksItem}>
          <div className={styles.number}>5</div>
          <div className={styles.cardColumn}>
            <p className={styles.cardColumnTitle}>
              Bulk orders (if selling in person)
            </p>
            <p className={styles.cardColumnText}>
              Delivered typically within 2-3 days!
            </p>
          </div>
        </div>

        <div className={styles.howItWorksItem}>
          <div className={styles.number}>6</div>
          <div className={styles.cardColumn}>
            <p className={styles.cardColumnTitle}>
              Send people to link if you don’t want to handle selling in person!
            </p>
            <p className={styles.cardColumnText}>
              Your collection page is your own shop!
            </p>
          </div>
        </div>

        <div className={styles.summury}>
          This is such a powerful tool that you can use any way you want,
          unlimited times.
        </div>
      </div>
      <div className={styles.lovePrizes}>
        <p className={styles.lovePrizesTitle}>Love Prizes?</p>
        <p className={styles.lovePrizesText}>
          All VIPs are entered into a monthly $100 Amazon giveaway.
        </p>
        <p className={styles.lovePrizesText2}>
          See previous winners here. Click to see what’s required to enter.
        </p>
        <p className={styles.follow}>
          • Follow, comment, repost, and tag us on social media
        </p>
        <div className={styles.social}>
          <img className={styles.icon} src={fbIcon} alt="FB" />
          <a className={styles.socialLink} href={facebookLink} target="_blank">
            Go To Fan Made Fits Facebook
          </a>
        </div>
        <div className={styles.social}>
          <img className={styles.icon} src={instIcon} alt="Inst" />
          <a className={styles.socialLink} href={instagramLink} target="_blank">
            Go To Fan Made Fits Instagram
          </a>
        </div>
        <div className={styles.social}>
          <img className={styles.icon} src={twitIcon} alt="Twit" />
          <a className={styles.socialLink} href={twitterLink} target="_blank">
            Go To Fan Made Fits Twitter
          </a>
        </div>
        <p className={styles.follow}>• Refer to your friends and family</p>
        <p className={styles.follow}>
          • Send us photo of you wearing Fan Made Fits product
        </p>
        <p className={styles.follow}>• Leave us a review!</p>
      </div>

      <section className={styles.infoBlock}>
        <p className={styles.infoBlockTitle}>Make Money by Being a VIP</p>
        <p className={styles.infoBlockText}>
          Our VIPS aren’t just customers. They are our brand ambassadors! Share
          a link to ANY PRODUCT (even your own custom design) to earn $10 off
          each purchase your friends make!
        </p>
        <p className={styles.infoBlockText2}>
          That means you can just create your own custom designs, and send
          people to the link and make money from people’s purchases!
        </p>
      </section>

      <section className={styles.infoBlock}>
        <p className={styles.infoBlockTitle}>Concierge Service - No Limits</p>
        <p className={styles.infoBlockText}>
          Want a new product that we don’t yet stock? Want special bulk pricing?
          Have a question that requires immediate attention? Let your concierge
          know.
        </p>
        <div className={styles.infoBlockButton}>
          <a
            className={styles.linkButton}
            href={`mailto:concierge@fanmadefits.com?subject='Concierge Order`}
          >
            <Button value="Talk to Concierge" className="buttonBlue" />
          </a>
        </div>
      </section>

      <section className={styles.infoBlock}>
        <p className={styles.infoBlockTitle}>
          How do I cancel my Fan Made Fits VIP Club membership?
        </p>
        <p className={styles.infoBlockText2}>
          To cancel, email us at 
          <a
            className={styles.linkText}
            href={`mailto:concierge@fanmadefits.com?subject='Concierge Order`}
          >
            support@fanmadefits.com {" "}
          </a>
          with your name and email used to sign up for the membership and we’ll
          cancel it right away. If you ever want to rejoin the club, just email
          in and we’ll be happy to help!
        </p>
      </section>

      <section className={styles.infoBlock}>
        <p className={styles.infoBlockTitle}>Have More Questions?</p>
        <p className={styles.infoBlockText2}>
          We’d love to answer them. Don’t hesitate to reach out to us at any
          time. Visit our support page 
          <Link to="/support" className={styles.linkText}>
            here
          </Link>
        </p>
      </section>
    </div>
  );
};

export default VipClub;
