import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { navigate } from 'utils/navigate'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import closeMenuImg from 'assets/close-menu-icon.svg'
import hamburgerMenuImg from 'assets/menu-icon.svg'
import logginedIcon from 'assets/loggedIn.svg'
import notLogginedIcon from 'assets/notLoggedIn.svg'
import searchIcon from 'assets/search.svg'
import searchBlack from 'assets/searchBlack.svg'
import { actionCreators, selectProducts } from 'state/HomePage/reducer'
import { selectors as selectorsLogin } from 'state/LoginPage/reducer'
import BurgerMenu from 'components/BurgerMenu'
import Input from 'components/Input'
import * as styles from './Header.module.scss'

const Header = ({ setSearchValue, setMenuCollection, products, isNeedDescription = false, gap = false }) => {
  const { shared } = useSiteMetadata()

  const logoImage = shared.LOGO_IMAGE.publicUrl
  const pageTitle = shared.HOME_TITLE
  const quickSearchKeys = shared.QUICK_SEARCH_KEYS

  const [isMenu, setIsMenu] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [value, setValue] = useState('')

  const trendingsRef = useRef()
  const overlayRef = useRef()
  const inputRef = useRef()

  const token = typeof localStorage !== 'undefined' && typeof sessionStorage !== 'undefined' ? !!localStorage.getItem('refreshToken') || !!sessionStorage.getItem('refreshToken') : false

  const toggleSearch = ({ key = null }) => {
    setIsMenu(false)
    if (isSearch) {
      if (!!key || !!value) {
        setSearchValue()
        navigate(`/search/${(key || value).trim()}`)
      }
      setIsSearch(false)
      document.removeEventListener('mousedown', handleClickOutsideSearch)
    } else {
      setIsSearch(true)
      document.addEventListener('mousedown', handleClickOutsideSearch)
    }
  }

  useEffect(() => {
    if (isMenu === true || isSearch === true) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }, [isMenu, isSearch])

  useEffect(() => {
    if (inputRef?.current?.focus) {
      inputRef.current.focus()
    }
  }, [isSearch])

  const submitSearch = e => {
    e.preventDefault()
    toggleSearch(e)
  }

  const handleClickOutsideSearch = e => {
    if (!trendingsRef?.current?.contains(e.target) && overlayRef?.current?.contains(e.target)) {
      setIsSearch(false)
      document.removeEventListener('mousedown', handleClickOutsideSearch)
    }
  }

  const onChangeSearch = debouncedValue => {
    setSearchValue()
    navigate(`/search/${debouncedValue.trim()}`)
    setIsSearch(false)
  }

  return (
    <div className={styles.header}>
      <nav className={styles.nav}>
        <img
          className={styles.logo}
          src={logoImage}
          alt='logo'
          onClick={() => {
            navigate('/')
            setMenuCollection(undefined)
          }}
        />

        <div className={styles.iconsContainer}>
          <div className={`${styles.headerSearch} ${isSearch ? styles.headerSearch_active : ''}`}>
            {isSearch && (
              <form onSubmit={submitSearch}>
                <Input placeholder='Search' onChange={setValue} containerClassName={styles.inputSearch} mode='onInput' autoFocus />
              </form>
            )}
            <img className={styles.searchIcon} src={searchIcon} alt='' onClick={toggleSearch} />
          </div>

          <img
            onClick={() => (token === false ? navigate('/login') : navigate('/profile'))}
            className={styles.userIcon}
            src={token === true ? logginedIcon : notLogginedIcon}
            alt=''
          />

          <img
            className={styles.burgerIcon}
            src={isMenu ? closeMenuImg : hamburgerMenuImg}
            alt=''
            onClick={() => {
              setIsMenu(!isMenu)
              setIsSearch(false)
            }}
          />
        </div>
      </nav>

      {isNeedDescription && (
        <div className={styles.box}>
          <div className={styles.description}>{pageTitle}</div>
        </div>
      )}
      {isMenu && <BurgerMenu handleCloseMenu={() => setIsMenu(false)} gap={gap} />}
      {isSearch && (
        <div className={gap === true ? styles.trendingWithGap : styles.trendings} ref={overlayRef}>
          <div className={styles.trendingsContent} ref={trendingsRef}>
            <div className={styles.trendingsNavigation}>
              <img src={searchBlack} alt='' onClick={toggleSearch} />
              <form onSubmit={submitSearch} className={styles.trendingsForm}>
                <Input onChange={setValue} containerClassName={styles.trendingSearch} mode='onInput' forwardRef={inputRef} />
              </form>
              <div className={styles.trendingsCancel} onClick={toggleSearch}>
                Cancel
              </div>
            </div>
            <div className={styles.trendingsBox}>
              <h2 className={styles.trendingsTitle}>trending</h2>
              {/* {products.slice(0, 10).map(product => (
                <Link className={styles.trendingsLink} key={product.id} to={`/products/${product.id}`}>
                  {product.productDisplayName}
                </Link>
              ))} */}
              {quickSearchKeys &&
                quickSearchKeys.split(',').map((key, index) => (
                  <Link className={styles.trendingsLink} key={index} onClick={() => onChangeSearch(key)}>
                    {key.trim()}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  products: selectProducts(state),
  loginState: selectorsLogin.selectLoginState(state)
})

const mapDispatchToProps = {
  setMenuCollection: actionCreators.setMenuCollection,
  setSearchValue: actionCreators.setSearchValue
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
