import React from "react";
import { Button } from "components";
import * as styles from "./QuotesTable.module.scss";

const QuotesTable = () => {
  return (
    <div className={styles.quotesContainer}>
      <p className={styles.quotesTitle}>
        Here Are Quotes For Your Custom Order
      </p>
      <div className={styles.table}>
        <div className={styles.tableRowTitle}>
          <div className={styles.tableTitle}>Steps to Custom Order</div>
          <div className={styles.tableSubtitleContainer}>
            <p className={styles.subtitleBig}>Non-VIP</p>
            <p className={styles.subtitleSmall}>Members</p>
          </div>
          <div className={styles.tableSubtitleContainer}>
            <p className={styles.subtitleBig}>VIP</p>
            <p className={styles.subtitleSmall}>Members</p>
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>1. Contact Concierge</p>
            <p className={styles.rowText2}>Fan Made Fits Concierge</p>
          </div>
          <div className={styles.rowItem2}>
            <p className={styles.rowTextRed}>FREE</p>
          </div>
          <div className={styles.rowItem3}>
            <p className={styles.rowTextRed}>FREE</p>
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>2. Create your designs</p>
            <p className={styles.rowText2}>
              Our designers create your custom design
            </p>
          </div>
          <div className={styles.rowItem2}>
            <p className={styles.rowTextBlack}>$40</p>
          </div>
          <div className={styles.rowItem3}>
            <p className={styles.rowTextRed}>FREE</p>
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>
              3. Confirm and finalize your order
            </p>
            <p className={styles.rowText2}>
              Unlimited edits until we get it right
            </p>
          </div>
          <div className={styles.rowItem2}>
            <p className={styles.rowTextBlack}>$40</p>
          </div>
          <div className={styles.rowItem3}>
            <p className={styles.rowTextRed}>FREE</p>
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>4. Make orders to our print shop</p>
            <p className={styles.rowText2}>
              Select quantity (Single or Bulk available)
            </p>
          </div>
          <div className={styles.rowItem2}>
            <p className={styles.rowTextBlack}>$25</p>
          </div>
          <div className={styles.rowItem3}>
            <p className={styles.rowTextBlack}>$17</p>
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={styles.rowItem1}>
            <p className={styles.rowText1}>5. Print and shipped</p>
            <p className={styles.rowText2}>Premium shipping to your address!</p>
          </div>
          <div className={styles.rowItem2}>
            <p className={styles.rowTextBlack}>$9.99</p>
          </div>
          <div className={styles.rowItem3}>
            <p className={styles.rowTextBlack}>$9.99</p>
          </div>
        </div>

        <div className={styles.contactButton}>
          <a
            className={styles.link}
            href={`mailto:concierge@fanmadefits.com?subject=Concierge Order`}
          >
            <Button value="Contact Concierge Now" className="button69" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuotesTable;
