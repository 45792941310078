import React, { useState, useEffect, useRef } from 'react'
import searchIcon from 'assets/searchBlack.svg'
import * as styles from './InputSearch.module.scss'

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}

const InputSearch = ({
  placeholder,
  onChange,
  isValid,
  isShowErrors = false,
  errorMessage,
  defaultValue,
  pattern = null,
  type,
  bigInput,
  searchSource = [],
  searchKeys = [],
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue)
  // eslint-disable-next-line no-unused-vars
  const [isSearch, setIsSearch] = useState(false)
  const debouncedValue = useDebounce(value, 100)
  const containerRef = useRef()

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (value !== defaultValue && type !== 'primary') {
      onChange && onChange(debouncedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, onChange])

  const onChangeHandler = event => {
    setValue(pattern ? pattern(event) : event.target.value)
  }

  const finishSearch = (_e, fastFinish) => {
    if (value !== defaultValue && debouncedValue) {
      onChange && onChange(fastFinish ? value : debouncedValue)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    finishSearch(e, true)
  }

  const container = !bigInput ? 'container' : 'containerBig'

  const handleClickOutsideSearch = e => {
    if (!containerRef?.current?.contains(e.target)) {
      setIsSearch(false)
    } else {
      setIsSearch(true)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearch, true)

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearch, true)
    }
  }, [])

  return (
    <>
      <div className={styles[container]} ref={containerRef}>
        <img className={styles.searchIcon} src={searchIcon} alt='' />
        <form onSubmit={handleSubmit}>
          <input className={styles.input} name='input' value={value} placeholder={placeholder} onChange={onChangeHandler} autoComplete='off' {...rest} />
        </form>
        {/* {type === 'primary' && <img onClick={finishSearch} className={styles.arrowIcon} src={arrowRight} alt='' />} */}

        {/* <Searchable searchSource={searchSource} keys={searchKeys} searchText={debouncedValue}>
          {({ data }) => (
            <div className={styles.suggestionsWrapper}>
              {!!data.length && isSearch && (
                <div className={styles.suggestions}>
                  <p className={styles.suggestionTitle}>Trending</p>
                  {data.slice(0, 5).map(item => (
                    <Link className={styles.suggestion} to={`/products/${item.id}`} key={item.id}>
                      {item.productDisplayName}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
        </Searchable> */}
      </div>

      <div className={!isShowErrors || isValid ? styles.errorMessageHidden : styles.errorMessage}>
        <p>{errorMessage}</p>
      </div>
    </>
  )
}

export default InputSearch
