import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import * as styles from "./Select.module.scss";

const covertValuesToGoogleStandard = (options) => {
  return options.map((option) => {
    switch (option.label) {
      case "SM":
        return { ...option, label: "S" };

      case "MD":
        return { ...option, label: "M" };

      case "LG":
        return { ...option, label: "L" };

      default:
        return option;
    }
  });
};

const Select = ({
  placeholder,
  value,
  onSelect,
  isValid,
  errorMessage,
  isShowErrors = false,
  options = [],
}) => {
  return (
    <div>
      <Dropdown
        options={covertValuesToGoogleStandard(options)}
        onChange={onSelect}
        value={value}
        placeholder={placeholder}
        controlClassName={
          !isShowErrors || isValid ? styles.select : styles.selectError
        }
        arrowClassName={styles.selectArrow}
        menuClassName={styles.selectMenu}
      />
      <div
        className={
          !isShowErrors || isValid
            ? styles.errorMessageHidden
            : styles.errorMessage
        }
      >
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

export default Select;
