import React, { useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import * as styles from "./SelectWithLabel.module.scss";

const SelectWithLabel = ({
  placeholder,
  alreadySelected = false,
  label,
  value,
  required,
  onSelect,
  isValid,
  errorMessage,
  isShowErrors = false,
  options = [],
  inputProps = {},
}) => {
  const [inputValue, setInputValue] = useState("");
  const [placeholderClass, setPlaceholderClass] = useState(alreadySelected ? '' : styles.placeholder);

  const onAutoFill = (e) => {
    const foundOption = options.find((option) => {
      const optionAutocomplete = option?.autocomplete
        ? option.autocomplete.toLowerCase()
        : option.label.toLowerCase();
      const autocomplete = e.currentTarget.value.toLowerCase();
      return optionAutocomplete === autocomplete;
    });
    foundOption && onSelect(foundOption);
  };

  // for browser to remember of selected value
  const handleSelect = (option) => {
    setPlaceholderClass('')
    setInputValue(option.label);
    onSelect && onSelect(option);
  };

  return (
    <>
      <div
        className={
          !isShowErrors || isValid ? styles.container : styles.containerError
        }
      >
        <div className={styles.label}>
          {required ? (
            <>
              <span className={styles.required}>*</span>
              {label}
            </>
          ) : (
            label
          )}
        </div>
        <input
          {...inputProps}
          value={inputValue}
          onChange={onAutoFill}
          className={styles.autofillInput}
        />
        <Dropdown
          options={options}
          onChange={handleSelect}
          value={value}
          placeholder={placeholder}
          placeholderClassName={`${styles.placeholderClassName} ${placeholderClass}`}
          controlClassName={styles.select}
          arrowClassName={styles.selectArrow}
          menuClassName={styles.selectMenu}
        />
      </div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.errorMessageHidden
            : styles.errorMessage
        }
      >
        <p>{errorMessage}</p>
      </div>
    </>
  );
};

export default SelectWithLabel;
