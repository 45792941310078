import React from "react";
import { Link } from "gatsby";
import { useSiteMetadata } from "hooks/use-site-metadata";
import * as styles from "./Menu.module.scss";

const Menu = ({ menu, onCollectionCliked, onShopClick, isfFullMenu }) => {
  const { shared } = useSiteMetadata();
  const blogLink = shared.BLOG_LINK || "/underconstruction";
  const newsLink = shared.NEWS_LINK || "/underconstruction";

  const menuItems = [
    { id: "Custom Order", link: "/custom-order" },
    { id: "FAQs", link: "/faq" },
    { id: "Support", link: "/support" },
    { id: "Vip Club", link: "/membership" },
    { id: "About Us", link: "/about-us" },
    { id: "Testimonials", link: "/testimonials" },
    { id: "News", link: newsLink },
    { id: "Blog", link: blogLink },
    { id: "Return & Refund Policy", link: "/refund-policy" },
    { id: "Shipping Policy", link: "/shipping-policy" },
    { id: "Terms And Conditions", link: "/terms" },
    { id: "Privacy Policy", link: "/privacy-policy" },
    { id: "Payment Policy", link: "/payment-policy" },
  ];

  return (
    <div className={styles.menuContainer}>
      {!isfFullMenu && (
        <>
          <div
            className={styles.menuItemTitleThin}
            onClick={() => onCollectionCliked(undefined)}
          >
            <span className={styles.arrowBack} />
            Categories
          </div>
          {menu?.items.map((collection) => (
            <div key={collection.key}>
              <div
                className={styles.menuItemTitle}
                onClick={() => onCollectionCliked(collection.name)}
              >
                {collection.name}
              </div>
            </div>
          ))}
        </>
      )}

      {isfFullMenu && (
        <>
          <div className={styles.menuItemLink} onClick={onShopClick}>
            Categories
          </div>
          {menuItems.map(({ id, link }) => {
            const internal = /^\/(?!\/)/.test(link);
            if (internal) {
              return (
                <li key={id}>
                  <Link className={styles.menuItemTitle} to={link}>
                    <div className={styles.menuItemLink}>{id}</div>
                  </Link>
                </li>
              );
            }
            return (
              <a href={link} target="_blank" className={styles.menuItemTitle}>
                <div className={styles.menuItemLink}>{id}</div>
              </a>
            );
          })}

          <div className={styles.usaFlagContainer}>
            <span className={styles.usaFlag} />
            United States
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
