import React from "react";
import * as styles from "./UpgradePageItem.module.scss";

const UpgradePageItem = (props) => (
  <div className={styles.premiumItem}>
    <img className={styles.premiumIcon} src={props.itemIcon} alt="" />
    <div className={styles.premiumInfo}>
      <p className={styles.premiumHeading}>{props.itemTitle}</p>
      <p className={styles.premiumText}>{props.itemText}</p>
    </div>
  </div>
);

export default UpgradePageItem;
