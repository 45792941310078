import React, { useEffect } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { useSiteMetadata } from "hooks/use-site-metadata";
import discoveryImg from "assets/paymentDiscovery.png";
import mastercardImg from "assets/paymentMastercard.png";
import visaImg from "assets/paymentVisa.png";
import secureImg from "assets/secure.png";
import facebookIcon from "assets/facebookIcon.svg";
import instagramIcon from "assets/instagramIcon.svg";
import twitterIcon from "assets/twitterIcon.svg";

import { customerAccountRefreshToken } from "state/LoginPage/actions";
import { selectLoginState } from "state/LoginPage/reducer";

import * as styles from "./Footer.module.scss";

const refreshToken =
  typeof localStorage !== "undefined" && typeof sessionStorage !== "undefined"
    ? localStorage.getItem("refreshToken") ||
      sessionStorage.getItem("refreshToken")
    : null;
const accessToken =
  typeof sessionStorage !== "undefined" &&
  sessionStorage.getItem("accessToken");
const expiresIn =
  (typeof sessionStorage !== "undefined" &&
    Date.parse(sessionStorage.getItem("expiresIn"))) ||
  0;
const now = Date.now();

const Footer = ({ loginState, customerAccountRefreshToken }) => {
  const { shared } = useSiteMetadata();

  const facebookLink = shared.FACEBOOK_LINK;
  const instagramLink = shared.INSTAGRAM_LINK;
  const twitterLink = shared.TWITTER_LINK;
  const blogLink = shared.BLOG_LINK || "/underconstruction";
  const newsLink = shared.NEWS_LINK || "/underconstruction";
  const logoImage = shared.LOGO_IMAGE.publicUrl;
  const supportAddress = shared.SUPPORT_ADDRESS;
  const supportPhone = shared.SUPPORT_PHONE;
  const supportEmail = shared.SUPPORT_EMAIL;

  console.log("new", newsLink);

  useEffect(() => {
    if (
      refreshToken &&
      (!accessToken || now - expiresIn > 0 || loginState.email === "")
    ) {
      customerAccountRefreshToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.footer}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Karla&family=Roboto:wght@400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.campaignContainer}>
          <img className={styles.footerLogo} src={logoImage} alt="logo" />
          <div className={styles.socialContainer}>
            {facebookLink && (
              <a
                href={facebookLink}
                target="blank"
                className={styles.socialItem}
              >
                <img className={styles.socialIcon} src={facebookIcon} alt="" />
              </a>
            )}
            {instagramLink && (
              <a
                href={instagramLink}
                target="blank"
                className={styles.socialItem}
              >
                <img className={styles.socialIcon} src={instagramIcon} alt="" />
              </a>
            )}
            {twitterLink && (
              <a
                href={twitterLink}
                target="blank"
                className={styles.socialItem}
              >
                <img className={styles.socialIcon} src={twitterIcon} alt="" />
              </a>
            )}
          </div>
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.infoTitle}>
            We hope you learned more about us and we would love to stay in touch
            with you!
          </div>
          <div className={styles.info}>
            Address: {supportAddress}
            <br />
            Phone: {supportPhone}
            <br />
            Email: {supportEmail}
          </div>
        </div>

        <div className={styles.containerRight}>
          <div className={styles.supportContainer}>
            <Link to="/custom-order" className={styles.linkItem}>
              Custom Order
            </Link>
            <Link to="/membership" className={styles.linkItem}>
              Vip Club
            </Link>
            <Link to="/testimonials" className={styles.linkItem}>
              Testimonials
            </Link>
            <Link to={newsLink} target="_blank" className={styles.linkItem}>
              News
            </Link>
            <Link to={blogLink} target="_blank" className={styles.linkItem}>
              Blog
            </Link>
          </div>

          <div className={styles.supportContainer}>
            <Link to="/privacy-policy" className={styles.linkItem}>
              Privacy Policy
            </Link>
            <Link to="/terms" className={styles.linkItem}>
              Terms And Conditions
            </Link>
            <Link to="/shipping-policy" className={styles.linkItem}>
              Shipping Policy
            </Link>
            <Link to="/refund-policy" className={styles.linkItem}>
              Return & Refund Policy
            </Link>
            <Link to="/payment-policy" className={styles.linkItem}>
              Payment Policy
            </Link>
          </div>

          <div className={styles.supportContainer}>
            <Link to="/about-us" className={styles.linkItem}>
              About us
            </Link>
            <Link to="/support" className={styles.linkItem}>
              Support
            </Link>
            <Link to="/faq" className={styles.linkItem}>
              FAQs
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.copyrightContainer}>
        <div className={styles.copyrightImageContainer}>
          <img src={secureImg} alt="SECURE" className={styles.copyrightImage} />
          <img src={visaImg} alt="VISA" className={styles.copyrightImage} />
          <img
            src={mastercardImg}
            alt="MASTERCARD"
            className={styles.copyrightImage}
          />
          <img
            src={discoveryImg}
            alt="DISCOVERY"
            className={styles.copyrightImage}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginState: selectLoginState(state),
});

const mapDispatchToProps = {
  customerAccountRefreshToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
