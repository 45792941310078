import React, { useState, useEffect } from "react";
import { Rifm } from "rifm";
import * as styles from "./ExpiryDateInput.module.scss";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

const parseDigits = (string) => (string.match(/\d+/g) || []).join("");

const formatExpiryDate = (string) => {
  const digits = parseDigits(string);
  const chars = digits.split("");
  return chars
    .reduce(
      (r, v, index) => (index === 2 || index === 4 ? `${r}-${v}` : `${r}${v}`),
      ""
    )
    .substr(0, 5);
};

const expiryDateMask = (string) => {
  const digits = parseDigits(string);
  const group1 = digits.slice(0, 2).padEnd(2, "M");
  const group2 = digits.slice(2, 4).padEnd(2, "Y");
  return `${group1}/${group2}`;
};

const ExpiryDateInput = ({
  placeholder,
  label,
  onChange,
  isValid,
  isShowErrors = false,
  errorMessage,
  defaultValue,
}) => {
  const [masked, setMasked] = React.useState("");
  const debouncedValue = useDebounce(masked, 300);

  useEffect(() => {
    if (masked !== defaultValue) {
      onChange && onChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, onChange]);

  return (
    <div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.inputContainer
            : styles.inputContainerError
        }
      >
        <div className={styles.inputLabel}>{label}</div>
        <div>
          <Rifm
            accept={/[\d]/g}
            mask={true}
            format={formatExpiryDate}
            replace={expiryDateMask}
            value={masked}
            onChange={setMasked}
          >
            {({ value, onChange }) => (
              <input
                type="tel"
                className={styles.input}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
              />
            )}
          </Rifm>
        </div>
      </div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.errorMessageHidden
            : styles.errorMessage
        }
      >
        <p>{errorMessage}</p>
      </div>
    </div>
  );
};

export default ExpiryDateInput;
