import React, { useState, useEffect } from "react";
import * as styles from "./Input.module.scss";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

const Input = ({
  className,
  placeholder,
  label,
  required,
  onChange,
  isValid,
  isShowErrors = false,
  errorMessage,
  defaultValue,
  containerClassName,
  pattern = null,
  type,
  mode = "onChange",
  bigInput,
  inputType,
  forwardRef,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value !== defaultValue) {
      onChange && onChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, onChange]);

  const onChangeHandler = (event) => {
    if (mode === "onChange") {
      setValue(pattern ? pattern(event) : event.target.value);
    } else {
      onChange && onChange(pattern ? pattern(event) : event.target.value);
    }
  };

  const container = bigInput
    ? "containerBig"
    : label
    ? "containerWithLabel"
    : "container";
  const containerError = bigInput
    ? "containerErrorBig"
    : label
    ? "containerErrorWithLabel"
    : "containerError";

  rest[mode] = onChangeHandler;

  return (
    <>
      <div
        className={`${
          !isShowErrors || isValid ? styles[container] : styles[containerError]
        } ${containerClassName}`}
      >
        <div className={label ? styles.label : ""}>
          {required ? (
            <>
              <span className={styles.required}>*</span>
              {label}
            </>
          ) : (
            label
          )}
        </div>
        <input
          className={className ? styles[className] : styles.input}
          name="input"
          value={value}
          placeholder={placeholder}
          type={inputType === "password" ? "password" : ""}
          ref={forwardRef}
          {...rest}
        />
      </div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.errorMessageHidden
            : styles.errorMessage
        }
      >
        <p>{errorMessage}</p>
      </div>
    </>
  );
};

export default Input;
