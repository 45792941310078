import React from "react";
import closeIcon from "assets/closeModalIcon.svg";
import * as styles from "./Modal.module.scss";

const Modal = ({ children, onClose = null }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modalContainer}>
        {onClose && (
          <div onClick={onClose}>
            <img className={styles.closeIcon} src={closeIcon} alt="" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
