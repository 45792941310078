// extracted by mini-css-extract-plugin
export var button = "Button-module--button--2t432";
export var buttonHuge = "Button-module--buttonHuge--RqlNO";
export var button69 = "Button-module--button69--_r769";
export var buttonMedium = "Button-module--buttonMedium--2FScA";
export var buttonSmall = "Button-module--buttonSmall--298ql";
export var buttonBlue = "Button-module--buttonBlue--1pWHE";
export var buttonDarkBlue = "Button-module--buttonDarkBlue--ynQZ8";
export var buttonDarkBlueHuge = "Button-module--buttonDarkBlueHuge--3C4OM";
export var ldsEllipsisContainer = "Button-module--ldsEllipsisContainer--1bWDi";
export var ldsEllipsis = "Button-module--ldsEllipsis--3AwGB";
export var ldsEllipsis1 = "Button-module--ldsEllipsis1--1idzE";
export var ldsEllipsis2 = "Button-module--ldsEllipsis2--2r9K6";
export var ldsEllipsis3 = "Button-module--ldsEllipsis3--uK-Vl";