import React, { useState, useEffect } from "react";
import * as styles from "./Textarea.module.scss";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

const Textarea = ({
  placeholder,
  label,
  onChange,
  isValid,
  isShowErrors = false,
  errorMessage,
  defaultValue,
  pattern = null,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (value !== defaultValue) {
      onChange && onChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, onChange]);

  const onChangeHandler = (event) => {
    setValue(pattern ? pattern(event) : event.target.value);
  };

  return (
    <>
      <div className={styles.label}>{label}</div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.container
            : styles.inputContainerError
        }
      >
        <textarea
          className={styles.input}
          name="textarea"
          rows="9"
          value={value}
          placeholder={placeholder}
          onChange={onChangeHandler}
          {...rest}
        ></textarea>
      </div>
      <div
        className={
          !isShowErrors || isValid
            ? styles.errorMessageHidden
            : styles.errorMessage
        }
      >
        <p>{errorMessage}</p>
      </div>
    </>
  );
};

export default Textarea;
